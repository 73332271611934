export default [
  {
    author: "Bill K.",
    title: "Go with Fast Labs!",
    text:
      "Can not recommend Fast Labs enough. They are able to create and implement your dreams into reality through websites, mobile apps and automation.",
  },
  {
    author: "Justin J.",
    title: "Happy with my new mobile app",
    text:
      "The Fastlabs team got the job done as promised and now I have my own mobile app to brag about. Well done!",
  },
  {
    author: "Tommy C.",
    title: "Great guys",
    text:
      "Andy and the others are awesome guys to work with, very courteous and attentive to details.",
  },
  {
    author: "Jordan L.",
    title: "Fast labs",
    text:
      "I had this startup idea for a while, but I was thinking I can’t afford the app development. Luckily I found Fast labs website and it turned out it’s pretty affordable to get the app done. Pretty happy with it and also got free advice on how to promote it. Recommended!",
  },
  {
    author: "Peter J.",
    title: "Good experience",
    text:
      "Before Fastlabs I tried an offshore team in India and that was a mess. But the Fastlabs guys got the job done without too much input on my side which saved me a lot of time. Nice design and works like a charm.",
  },
  {
    author: "Megan W.",
    title: "Fast, low cost and great quality",
    text:
      "I don’t know how they do it, but they actually deliver on those promises. Got my simple app built in a week for just 5 grand. Other people quoted me 50k and 3 months worth of development. So I guess I saved 45k which is not too shabby.",
  },
  {
    author: "John D.",
    title: "Made in USA",
    text:
      "I had a bad experience before with an overseas contractor and decided this time I’d go with the US based developer even if I pay more. Turned out that not only I didn’t pay more, I actually paid less and the job was done very well.",
  },
  {
    author: "Charlie H.",
    title: "Love my new app",
    text:
      "I was looking for a developer for my app idea and then I saw a fastlabs ad on google and I got the app built and published on the app store. Love it and thank you fastlabs.",
  },
  {
    author: "Yoakim C.",
    title: "Recommended",
    text:
      "Great company, work done as promised for a very modest budget. Highly recommended.",
  },
  {
    author: "Johnny M.",
    title: "Better than Upwork",
    text:
      "My agency used to go to Upwork to hire mobile app developers. Not anymore! Fastlabs costs even less and very reliable subcontractor. If you run an agency, try them and see!",
  },
  {
    author: "Cody S.",
    title: "You must be kidding me!",
    text:
      "I didn’t order the app yet, but the guys seem very professional and at what, 5000 for an app? That’s crazy cheap. Going to order my app asap.",
  },
  {
    author: "William R.",
    title: "Highly recommended",
    text:
      "I can only say that Fastlabs actually do what they promise with a good quality and on a fixed budget. Go with them.",
  },
  {
    author: "John O.",
    title: "Great group of people",
    text:
      "First I was skeptical if it’s possible to build an app so fast and cheap, but they actually did. Very friendly, courteous and keep their promises. They are based in Miami, in the same timezone as me. Very convenient. The only thing I’d change is they require payment upfront, I’d rather pay in a couple of installments. But that’s understandable and fine with me. Overall, a nice group of people doing a great job!",
  },
  {
    author: "Darrel K.",
    title: "Job well done",
    text:
      "Booked my free consultation, talked with Andy the co-founder. Paid my invoice and in about 10 days got the working app. We had a few changes, which were done in a couple of days and boom - we have an app! Good stuff.",
  },
  {
    author: "Juan S.",
    title: "Website into app",
    text:
      "I run a small online store and decided to turn it into a mobile app. These guys helped me a lot to understand what I need and how it should work. Also very cheap comparing to other agencies I talked too.",
  },
];
